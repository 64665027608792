import React from 'react'
import styled from 'styled-components'

// Components
import Layout from 'components/layout/Layout'
import CustomLink from 'components/shared/CustomLink'

const StyledCustomLink = styled(CustomLink)`
  color: ${({ theme }) => theme.color.text.contrast};
  &:hover {
    text-decoration: underline;
  }
`

const NotFoundPage = () => (
  <Layout>
    <div className="container py-5" style={{ height: '70vh' }}>
      <h1>404</h1>
      <p>
        Helaas, deze pagina kan niet worden gevonden.{' '}
        <StyledCustomLink to="/">Terug naar home</StyledCustomLink>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
